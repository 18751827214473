/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"

import Header from "./header"

import "react-image-lightbox/style.css"
import "./layout.scss"

require("typeface-ibm-plex-sans")

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              siteUrl
            }
          }
          contentfulNavigation(name: { eq: "footer" }) {
            name
            content {
              __typename
              ... on ContentfulPage {
                id
                slug
                title
              }
              ... on ContentfulLink {
                id
                url
                label
                openInNewWindow
              }
            }
          }
        }
      `}
      render={data => (
        <div className="frame">
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
          <footer>
            © {new Date().getFullYear()} yonderfx •{" "}
            {data.contentfulNavigation.content.map(item => {
              if (item.__typename === "ContentfulLink") {
                if (item.openInNewWindow) {
                  return (
                    <Fragment key={item.id}>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.label}
                      </a>{" "}
                      •{" "}
                    </Fragment>
                  )
                } else {
                  return (
                    <Fragment key={item.id}>
                      <a href={item.url}>{item.label}</a> •{" "}
                    </Fragment>
                  )
                }
              }
              if (item.__typename === "ContentfulPage") {
                return (
                  <Fragment key={item.id}>
                    <Link to={`/${item.slug}`}>{item.title}</Link> •{" "}
                  </Fragment>
                )
              }
              return null
            })}
            links may contain affiliate codes
          </footer>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
