import React, { Component } from "react"
import Lightbox from "react-image-lightbox"

export default class Gallery extends Component {
  state = { lightboxOpen: false }

  render() {
    return (
      <>
        <h3>{this.props.node.data.target.fields.title["en-US"]}</h3>
        <div className="gallery">
          {this.props.node.data.target.fields.content["en-US"].map(image => (
            <div key={image.sys.id} className="item">
              <a href={`${image.fields.file["en-US"].url}?w=1920`}>
                <img
                  src={`${
                    image.fields.file["en-US"].url
                  }?w=360&h=360&fit=thumb&fm=jpg&fl=progressive`}
                  alt={image.fields.file["en-US"].title}
                />
              </a>
            </div>
          ))}
        </div>
        {this.state.lightboxOpen && <Lightbox />}
      </>
    )
  }
}
