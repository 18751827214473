import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Location } from "@reach/router"

const Header = ({ siteTitle }) => (
  <header>
    <h1>
      <Link to="/">{siteTitle}</Link>
    </h1>
    <nav>
      <Location>
        {({ location }) => (
          <StaticQuery
            query={graphql`
              query {
                contentfulNavigation(name: { eq: "main" }) {
                  name
                  content {
                    __typename
                    ... on ContentfulPage {
                      id
                      slug
                      title
                    }
                    ... on ContentfulLink {
                      id
                      url
                      label
                      openInNewWindow
                    }
                  }
                }
              }
            `}
            render={data => (
              <ul>
                {data.contentfulNavigation.content.map(item => {
                  if (item.__typename === "ContentfulLink") {
                    if (item.openInNewWindow) {
                      return (
                        <li key={item.url}>
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.label}
                          </a>{" "}
                        </li>
                      )
                    } else {
                      return (
                        <li key={item.url}>
                          <a href={item.url}>{item.label}</a>
                        </li>
                      )
                    }
                  }
                  if (item.__typename === "ContentfulPage") {
                    let { id, slug, title } = item
                    if (slug === "start") {
                      slug = ""
                      title = "Start"
                    }
                    return (
                      <li key={id}>
                        <Link
                          to={`/${slug}`}
                          className={
                            location.pathname === `/${slug}` ? "active" : ""
                          }
                        >
                          {title}
                        </Link>
                      </li>
                    )
                  }
                  return null
                })}
              </ul>
            )}
          />
        )}
      </Location>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
