import React from "react"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Gallery from "./components/gallery"

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>

export default {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
      <img
        src={`${
          node.data.target.fields.file["en-US"].url
        }?w=960&fm=jpg&fl=progressive`}
        alt=""
      />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (node.data.target.sys.contentType.sys.id === "gallery")
        return <Gallery node={node} />
    },
  },
}
